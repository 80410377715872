import React from "react";

import type { HeadFC } from "gatsby";
import { graphql } from "gatsby";

import { BaseHead, CategoryList, Layout } from "../components";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";

const IndexPage = ({
  data: {
    markdownRemark: { html },
  },
}: any) => {
  return (
    <Layout>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h2" component="h1">
          JB Créations Céramique
        </Typography>
        <Box dangerouslySetInnerHTML={{ __html: html }} sx={{ fontSize: 18 }} />
      </Box>

      <Box>
        <CategoryList />
      </Box>

      <Box sx={{ maxWidth: 500, height: 500, margin: "0 auto" }}>
        <iframe
          src="https://player.vimeo.com/video/748545110?h=08acf8cefb&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
          frameborder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowfullscreen
          style={{ width: "100%", height: "100%" }}
          title="10000000_1758009477872293_4542614402707792670_n.mp4"
        ></iframe>
        <script src="https://player.vimeo.com/api/player.js"></script>
      </Box>
    </Layout>
  );
};

export default IndexPage;

export const Head: HeadFC = () => <BaseHead title="JB Créations céramique" />;

export const query = graphql`
  query HomeQuery {
    markdownRemark(frontmatter: { key: { eq: "presentation" } }) {
      html
    }
  }
`;
